
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { firebaseErrors } from "../common/methods/firebaseErrors";
import googleSignIn from "../common/methods/googleSignin";
import { getAuth, signInWithEmailAndPassword } from "@firebase/auth";
import * as Yup from "yup";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const loading = ref(false);
    const submitButton = ref<HTMLElement | null>(null);
    const router = useRouter();

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(6).required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      loading.value = true;
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      const auth = getAuth();
      signInWithEmailAndPassword(auth, values.email, values.password)
        .then(() => {
          router.push({ name: "dashboard" });
        })
        .catch((error) => {
          loading.value = false;
          //Deactivate indicator
          submitButton.value?.removeAttribute("data-kt-indicator");
          firebaseErrors(error.code);
        });
    };

    // Google sign in
    const signInWithGoogle = () => {
      googleSignIn();
    };

    return {
      login,
      loading,
      onSubmitLogin,
      signInWithGoogle,
      submitButton,
    };
  },
});
